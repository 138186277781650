<template>
  <b-modal id="messages-modal"
           title="Messages"
           title-class="font-weight-bold w-100"
           size="lg"
           :ok-only="true"
           @shown="getMessages"
           body-class="p-4"
           scrollable
  >
    <template v-if="loaded">
      <div v-for="(message, index) in messages" :key="index" v-lazy-container="{ selector: 'img' }">
        <SystemItem :message="message" v-if="message.author_username === 'ANYTASKSYSTEM'" />
        <ChatItem v-else :message="message" :current-user="buyerUsername" />
      </div>
    </template>
    <div class="mx-auto my-5 text-center" v-else>
      <b-spinner large label="Large Spinner"/>
      <div>Loading data</div>
    </div>
  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'
import ChatItem from '@/components/Message/ChatItem.vue'
import SystemItem from '@/components/Message/SystemItem.vue'

export default {
  name: 'AdminMessagesModal',
  components: { SystemItem, ChatItem },
  props: {
    thread: {
      type: String,
      required: true
    },
    buyerUsername: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      hash: null,
      messages: []
    }
  },
  watch: {
    'thread': function (val) {
      this.hash = val
    },
    'buyer_username': function (val) {
      this.buyerUsername = val
    }
  },
  methods: {
    getMessages () {
      this.loaded = false
      ApiService.getMessages(this.hash).then((resp) => {
        this.messages = resp.data.data.reverse()
        this.loaded = true
      })
    },
    displayDatetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }

  }
}
</script>
