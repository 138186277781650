<template>
  <div class="container-fluid">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Job Boards', 'link': $router.resolve({ name: 'admin_job_landing' }).href },
      { 'name': 'Pending', 'link': $router.resolve({ name: 'admin_job_pending' }).href },
      { 'name': 'Reviewing' }
    ]"/>

    <div class="main-container">
      <h1 class="m-2 font-weight-bold">Job Boards - Pending Review</h1>
      <div class="alert alert-danger" v-if="errors.length > 0">
        <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
      </div>
      <div class="row mb-4" v-if="spinner.content_loaded">
        <div class="col-8">
          <job-card class="job-container"
                    :is-preview="true"
                    :can-make-offer="false"
                    :job="this.item"
                    :is-seller="false"
                    :display-job-button="false"
          />
        </div>
        <div class="col-4">
          <div class="content-box p-4">
            <div class="mb-4">
              <h4 class="font-weight-bold text-left border-bottom">Accept Job</h4>
              <button class="btn btn-success w-100" @click="handleAccept()">
                <b-spinner small label="Small Spinner" v-if="spinner.accept_button"/>
                <span v-else>I am happy to make this live</span>
              </button>
            </div>
            <div class="text-left">
              <h4 class="font-weight-bold text-left border-bottom">Decline Job</h4>
              <div>
                <label>Please enter a reason why it should not go live</label>
                <div class="alert alert-warning small">
                  <i class="fa-solid fa-info-circle mr-1"/>
                  This text will be sent to the user. Please be brief and concise.
                </div>
                <select class="mb-2 custom-select" @change="selectedPreDefined($event)">
                  <option value="">Select a declined response...</option>
                  <option v-for="(value, index) in preDefinedResponses" :key="index" :value="value.text">{{ value.title }}</option>
                </select>
                <textarea class="w-100" rows="5" v-model="reason"></textarea>
              </div>
              <button class="btn btn-danger w-100" @click="handleDecline()">
                <b-spinner small label="Small Spinner" v-if="spinner.declined_button"/>
                <span v-else>Submit reason for declining job</span>
              </button>
            </div>
          </div>

          <div class="content-box p-4" v-if="notes.length > 0">
            <h4 class="font-weight-bold text-left border-bottom mb-3">Notes</h4>
            <div class="alert bg-dark text-white" v-for="(note, index) in notes" :key="index">
              <div class="text-left mb-2">{{ note.message }}</div>
              <div class="small text-right">
                <i class="fa-solid" :class="note.display ? 'fa-lock-open' : 'fa-lock'"></i> | {{ moment(note.date_created).format('LLL') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mx-auto my-5">
        <b-spinner large label="Large Spinner"/>
        <div>{{ $t('loading') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../apiService'
import JobCard from '../../../components/Job/JobCard'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminJobPendingDetail',
  components: { JobCard, Breadcrumb },
  data () {
    return {
      item: {},
      notes: [],
      reason: '',
      errors: [],
      spinner: {
        content_loaded: false,
        declined_button: false,
        accept_button: false
      },
      preDefinedResponses: [
        {
          title: 'Description needs to be clearer.',
          text: 'Sellers like detailed descriptions of exactly what would like delivered.  Include as much detail as possible.'
        }, {
          title: 'Final Product needs to be clearer.',
          text: 'You must let the seller know what you expect as a final product. What would you like delivered? This could be a certain digital file type (such as pdf or multiple image formats or it could be the time they will spend on your job etc.)'
        }, {
          title: 'Lack of information in "Description"',
          text: 'Sorry, there is not enough information in your description/ field for your job to be clear to the sellers so they can understand what you will require.  Please insert further details and resubmit your job.'
        }, {
          title: 'Job not in English',
          text: 'Sorry, at this time AnyTask only supports jobs written in English. We hope to bring other languages to AnyTask in the future, but in the meantime, resubmit your job in English.'
        }, {
          title: 'Inappropriate ‘Username’, ‘Job, and or ‘Job Header’.',
          text: 'Your Username/Job/Job Header is not suitable for approval and does not meet the Terms and Conditions.'
        }, {
          title: 'Illegal Job (I.E. Drugs etc..)',
          text: 'Your Job is not suitable for approval and does not meet the Terms and Conditions of AnyTask.com.'
        }, {
          title: 'Terms and Conditions',
          text: 'Your Job is not suitable for approval and does not meet the Terms and Conditions of AnyTask.com.'
        }, {
          title: 'Physical Products',
          text: 'Sorry, we do not accept jobs requesting physical products.'
        }, {
          title: 'Donation requests.',
          text: 'Sorry, we do not accept Donation requests, AnyTask is a digital freelancer platform.'
        }, {
          title: 'Money Making schemes/ Betting schemes/No trading systems.',
          text: 'AnyTask does not accept jobs of this type, they are against the Terms and Conditions.'
        }, {
          title: 'Contact Details in Description.',
          text: 'Please do not include contact details in your job description, sellers can contact you through the AnyTask messaging system. Communicating outside of the platform will negate AnyTaskProtection.'
        }, {
          title: 'Selling ETN or Cryptocurrencies.',
          text: 'Sorry, you cannot list a job to buy cryptocurrencies.  It is against the Terms and Conditions.'
        }, {
          title: 'Other Unacceptable (unlikely to be able to resubmit).',
          text: 'Sorry but your job is not suitable for AnyTask and is against the Terms and Conditions.'
        }, {
          title: 'Social media likes.',
          text: 'Unfortunately, we are unable to approve tasks requesting social media likes and follows.'
        }, {
          title: 'Remote computer support',
          text: 'Unfortunately, we are unable to approve tasks requesting remote computer support.'
        }, {
          title: 'Inappropriate Images',
          text: 'Kindly replace your job images; some images are not allowed. Feel free to edit and resubmit your job.'
        }, {
          title: 'Photo Not Compatible with the description',
          text: 'Sorry, we can see that your job description is not compatible with your job image display. Please amend and resubmit your Job.'
        }, {
          title: 'Link in Description',
          text: 'Sorry, we can see that you have included a personal link in your job description. Please remove this and resubmit your job for review.'
        }, {
          title: 'Inappropriate Photo',
          text: 'Sorry, we can see that one of your images is inappropriate and not compatible with the Terms and Conditions. Please amend and resubmit your job.'
        }, {
          title: 'Contact Details in Job Photo',
          text: 'Sorry, we can see that your Job Photo contains visible contact numbers/ links details / email addresses and is not suitable for approval and does not meet our Terms & Conditions. Kindly remove the details and resubmit.'
        }, {
          title: 'Promoting Different Platform',
          text: 'Sorry, but we\'ve noticed that your Job description appears to promote another platform, this is not acceptable. Please feel free to make the necessary adjustments and resubmit your Job for review'
        }, {
          title: 'Header Not Suitable To The Description',
          text: 'Sorry, we can see that your Job Header is not related to your Job description. Please amend and resubmit your Job.'
        }, {
          title: 'Typo In Header',
          text: 'Sorry, we can see that there is an error in your Job Header. Please amend and resubmit your Job.'
        }, {
          title: 'Services have been offered in a job posting.',
          text: 'We are sorry, but the job board is for buyers to post requirements for the work they would like done. As a freelancer offering your services, please list your tasks for the buyers to review and purchase. You can also watch the job board and you can submit an offer for any jobs you are able to complete.'
        }
      ]
    }
  },
  created () {
    this.spinner.content_loaded = false
    ApiService.getAdminJob(this.$route.params.hash).then((resp) => {
      this.item = resp.data.data
      ApiService.getJobNotes(this.$route.params.hash).then((resp) => {
        this.notes = resp.data.data.reverse()
        this.spinner.content_loaded = true
      })
    })
  },
  methods: {
    selectedPreDefined (event) {
      this.reason = event.target.value
    },
    handleAccept () {
      this.spinner.accept_button = true
      ApiService.postJobResponseReview(this.$route.params.hash, { 'action': 1, 'reason': 'All good' }).then((resp) => {
        this.$router.push({ name: 'admin_job_pending' })
      }).catch((e) => {
        if (e.response.status === 400) {
          for (let key in e.response.data.errors.children) {
            let content = e.response.data.errors.children[key]
            if (content.errors !== undefined) {
              content.errors.forEach((error) => {
                this.errors.push(error)
              })
            }
          }
        }
      }).finally(() => {
        this.spinner.accept_button = false
      })
    },
    handleDecline () {
      this.spinner.declined_button = true
      this.errors = []

      if (this.reason.length < 10) {
        this.errors.push('Please enter a longer reason.')
        this.spinner.declined_button = false
        return
      }

      ApiService.postJobResponseReview(this.$route.params.hash, { 'action': 0, 'reason': this.reason }).then((resp) => {
        this.$router.push({ name: 'admin_job_pending' })
      }).catch((e) => {
        if (e.response.status === 400) {
          for (let key in e.response.data.errors.children) {
            let content = e.response.data.errors.children[key]
            if (content.errors !== undefined) {
              content.errors.forEach((error) => {
                this.errors.push(error)
              })
            }
          }
        }
      }).finally(() => {
        this.spinner.declined_button = false
      })
    }
  }
}
</script>

<style scoped>
.job-container {
  border-radius: 1.25rem;
}

::v-deep .job-container ul li {
  list-style-type: disc!important;
}

::v-deep .job-container ol li {
  list-style-type: auto!important;
}
</style>
