<template>
  <div>
    <div class="container-fluid">
      <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Job Boards', 'link': $router.resolve({ name: 'admin_job_landing' }).href },
      { 'name': 'Pending' }
    ]"/>
      <div class="main-container">
        <h1 class="mb-2 font-weight-bold">Job Boards - Pending</h1>
        <div class="p-3">

          <template v-if="loading">

            <div v-if="items.length === 0">
              <i class="fa-solid fa-5x fa-person-circle-check mb-3"></i>
              <p>Good job, there are no jobs pending</p>
            </div>
            <div v-else class="bg-white p-3 rounded">
              <div class="box-title border-bottom mb-3">
                <span class="small">{{ paginationInfo }}</span>
              </div>
              <table class="table table-striped table-bordered add-manage-table">
                <thead>
                <tr>
                  <th>Username</th>
                  <th>Title</th>
                  <th>Submitted on</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td>{{ item.user.username }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ moment(item.date_modified).format('LL') }}</td>
                  <td class="text-right">
                    <router-link :to="{ name: 'admin_job_pending_details', params: { 'hash': item.hash } }" class="btn btn-primary">Review Job</router-link>
                  </td>
                </tr>
                </tbody>
              </table>

              <div class="d-flex justify-content-center mt-4">
                <Pagination v-if="pagination.total > pagination.per_page" :per-page="pagination.per_page" :current-page="pagination.page" :total="pagination.total"
                            v-on:paginationUpdate="getJobListing"/>
              </div>
            </div>
          </template>
          <div v-else class="mx-auto my-5">
            <b-spinner large label="Large Spinner"/>
            <div>{{ $t('loading') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import ApiService from '../../../apiService'
import Pagination from '../../../components/Global/Pagination'

export default {
  name: 'AdminJobPending',
  components: {
    Pagination,
    Breadcrumb
  },
  data () {
    return {
      loading: false,
      items: [],
      pagination: {
        per_page: 10,
        page: 1,
        total: 0
      }
    }
  },
  async created () {
    await this.getJobListing(1)
  },
  methods: {
    async getJobListing (page = 1) {
      this.loading = false
      await ApiService.getAdminJobListing({ 'status': 1 }, page).then((resp) => {
        this.pagination = resp.data.data.pagination
        this.items = resp.data.data.results
        this.loading = true
      })
    }
  },
  computed: {
    paginationInfo () {
      const currentTotalPosition = this.pagination.per_page * (this.pagination.page - 1)
      return this.$t('showing', { from: currentTotalPosition + 1, to: this.items.length + currentTotalPosition, total: this.pagination.total })
    }
  }
}
</script>
