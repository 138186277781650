<template>
  <div>
    <b-modal id="job-offer-modal"
             :title="`Job Offer for '${job.title}'`"
             title-class="font-weight-bold w-100"
             size="xl"
             :ok-only="true"
             @show="loadOffers"
             scrollable
    >
      <div class="m-3">
        <table class="table table-striped table-bordered add-manage-table bg-white" v-if="loaded">
          <thead>
          <tr class="text-center">
            <th>Username</th>
            <th>Status</th>
            <th>Price</th>
            <th>Delivery</th>
            <th>Revisions</th>
            <th>Date Modified</th>
            <th>Date Created</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in offers" :key="index" class="text-center">
            <td class="text-left">
              {{ item.user.username }}
            </td>
            <td>{{ $t('job.offer.status')[item.status] }}</td>
            <td><span v-if="item.status > 0">${{ item.price.toFixed(2) }}</span></td>
            <td><span v-if="item.status > 0">{{ $tc('job.offer.delivery.slide.value', (item.delivery / 24)) }}</span></td>
            <td><span v-if="item.status > 0">{{ item.revisions.toFixed() }}</span></td>
            <td>{{ displayDatetime(item.date_modified) }}</td>
            <td>{{ displayDatetime(item.date_created) }}</td>
            <td class="text-right">
              <router-link :to="{ name: 'admin_user', params: { hash: item.user.hash }, hash: '#messages' }" target="_blank" class="btn btn-primary">Inbox</router-link>
              <button class="btn btn-dark ml-2" @click="actionAudit(index)">Audit</button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="mx-auto my-5 text-center" v-else>
          <b-spinner large label="Large Spinner"/>
          <div>Loading data</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'AdminJobOfferModal',
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      offers: []
    }
  },
  methods: {
    async loadOffers () {
      this.loaded = false
      await ApiService.getJobOffers(this.job.hash).then((resp) => {
        this.offers = resp.data.data
        this.loaded = true
      })
    },
    actionAudit (index) {
      this.$emit('job-offer-audit-modal', this.offers[index].audits)
    },
    displayDatetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  }
}
</script>
