<template>
  <div class="container-fluid">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Job Boards' }
    ]"/>
    <div class="main-container">
      <h1 class=" mb-2"><strong>Job Boards</strong></h1>
      <div class="row mt-5 justify-content-center">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4">
          <Cards type="svg" image="fa-hourglass-half" title="Pending Jobs" :route="'/admin/job/pending'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4">
          <Cards type="svg" image="fa-person-chalkboard" title="List Jobs" :route="'/admin/job/list'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4">
          <Cards type="svg" image="fa-person-circle-question" title="Monitor Jobs" :route="'/admin/job/monitor'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cards from '../../../components/Admin/AdminCards'
import { mapActions } from 'vuex'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminJobLandingPage',
  components: {
    Breadcrumb,
    Cards
  },
  mounted () {
    this.buildUser()
  },
  methods: {
    ...mapActions([
      'buildUser'
    ])
  }
}
</script>
