<template>
  <b-modal id="job-offer-audit-modal"
           :title="`Job offer audits for '${job.title}'`"
           title-class="font-weight-bold w-100"
           size="xl"
           :ok-only="true">
    <div class="m-3">
      <table class="table table-striped table-bordered add-manage-table bg-white text-center">
        <thead>
        <tr>
          <th>Action</th>
          <th>Field / From / To</th>
          <th>Action by</th>
          <th>Date Created</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(audit, index) in job.audits" :key="index">
          <td>{{ audit.type }}</td>
          <td>
            <div v-for="(value, index) in buildDifference(audit.old_row_data, audit.new_row_data)" :key="index" class="row text-left">
              <div class="col font-weight-bold text-capitalize">{{ value.field }}:</div>
              <div class="col text-warning">{{ value.from }}</div>
              <div class="col text-success">{{ value.to }}</div>
            </div>
          </td>
          <td>{{ audit.created_by.username }}</td>
          <td>{{ displayDatetime(audit.date_created) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'AdminJobOfferAuditModal',
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  methods: {
    buildDifference (oldValue, newValue) {
      const diff = []
      if (oldValue === null || newValue === null) {
        for (let value of ['status', 'price', 'revisions', 'delivery']) {
          diff.push({ field: value, from: oldValue === null ? '' : oldValue[value], to: newValue === null ? '' : newValue[value] })
        }
        return diff
      }

      const getObjectDiff = (obj1, obj2) => {
        return Object.keys(obj1).reduce((result, key) => {
          if (!obj2.hasOwnProperty(key)) {
            result.push(key)
          } else if (JSON.stringify(obj1[key]) === JSON.stringify(obj2[key])) {
            const resultKeyIndex = result.indexOf(key)
            result.splice(resultKeyIndex, 1)
          }
          return result
        }, Object.keys(obj2))
      }

      getObjectDiff(oldValue, newValue).forEach(value => {
        diff.push({ field: value, from: oldValue[value], to: newValue[value] })
      })
      return diff
    },
    displayDatetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  }
}
</script>
