<template>
  <b-modal id="job-preview-modal"
           :title="`Job Preview '${job.title}'`"
           title-class="font-weight-bold w-100"
           size="xl"
           :ok-only="true"
           @show="getJob"
           scrollable
  >
    <div class="container-fluid">
      <div class="m-3" v-if="loaded">
        <create-section1 :job="item" :display-buttons="false"/>
        <create-section2 :job="item" :display-buttons="false"/>
        <create-section3 :job="item" :display-buttons="false" :allow-upload="false"/>
      </div>
      <div class="mx-auto my-5 text-center" v-else>
        <b-spinner large label="Large Spinner"/>
        <div>Loading data</div>
      </div>
    </div>

  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'
import CreateSection1 from '../../MyAccount/Job/CreateEditSection1'
import CreateSection2 from '../../MyAccount/Job/CreateEditSection2'
import CreateSection3 from '../../MyAccount/Job/CreateEditSection3'

export default {
  name: 'AdminJobPreviewModal',
  components: { CreateSection3, CreateSection2, CreateSection1 },
  props: {
    job: {
      type: Object,
      request: true
    }
  },
  data () {
    return {
      loaded: false,
      item: null
    }
  },
  methods: {
    async getJob () {
      this.loaded = false
      await ApiService.getAdminJob(this.job.hash).then((resp) => {
        this.item = resp.data.data
        this.loaded = true
      })
    }
  }
}
</script>
