<template>
  <div class="container-fluid">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Job Boards', 'link': $router.resolve({ name: 'admin_job_landing' }).href },
      { 'name': 'Monitor' }]"/>

    <div class="main-container">
      <h1 class="mb-2 font-weight-bold">Job Boards - Monitor</h1>

      <div class="bg-white border rounded p-4 mb-5">
        <div class="d-flex justify-content-center">
          <div class="input-group rounded">
            <input type="text" class="form-control" placeholder="Enter username" v-model="searchTerm">
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="getJobItems(1)">Search</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loaded">
        <div v-if="items.length === 0">
          <i class="fa-solid fa-5x fa-person-circle-exclamation mb-3"></i>
          <p>Looks like there's no job results found</p>
        </div>
        <div v-else class="bg-white p-3 rounded">
          <div class="box-title border-bottom mb-3">
            <span class="small">{{ paginationInfo }}</span>
          </div>
          <div class="text-left" v-for="(item, index) in items" :key="index" :class="{ 'bg-light': highlightRow.includes(item.thread_hash + '-' + item.last_message_date) }">
            <div class="d-flex border rounded p-3 mb-4">
              <div class="align-content-center border-right mr-2 pr-2 text-center">
                <router-link :to="{name:'admin_user', params:{hash: item.buyer_hash}}" target="_blank" class="d-block mb-2" title="View Buyer profile">{{ item.buyer_username }}</router-link>
                <router-link :to="{name:'admin_user', params:{hash: item.seller_hash}}" target="_blank" class="d-block mb-2" title="View Seller profile">{{ item.seller_username }}</router-link>
                <button class="btn btn-outline-primary btn-sm w-100" @click="showMessages(item.thread_hash, item.buyer_username)">View Messages</button>
              </div>
              <div class="ml-3 w-100" @click="checked(item.thread_hash, item.last_message_date)">
                <div class="d-flex justify-content-between mb-2">
                  <div>
                    <b-badge variant="primary" v-if="item.job_offer_status === 0">First Contact</b-badge>
                    <b-badge variant="warning" v-if="item.job_offer_status === 1">Offer Made</b-badge>
                    <b-badge variant="secondary" v-if="item.job_offer_status === 2">Awaiting Purchase</b-badge>
                    <b-badge variant="danger" v-if="item.job_offer_status === 3">Cancel</b-badge>
                    <b-badge variant="success" v-if="item.job_offer_status === 4">Accepted</b-badge>
                    {{ item.job_title }}
                  </div>
                  <div class="small text-right">{{ displayDatetime(item.last_message_date)  }}</div>
                </div>
                <div class="small">{{ item.last_message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div class="mx-auto my-5">
          <b-spinner large label="Large Spinner"></b-spinner>
          <div class="mt-2">getting jobs...</div>
        </div>
      </template>

      <div class="d-flex justify-content-center">
        <Pagination v-if="pagination.total > pagination.per_page" :per-page="pagination.per_page" :current-page="pagination.page" :total="pagination.total"
                    v-on:paginationUpdate="getJobItems"/>
      </div>
    </div>
    <AdminMessagesModal :thread="selected.threadHash" :buyerUsername="selected.buyerUsername"/>
  </div>
</template>

<script>
import Pagination from '../../../components/Global/Pagination'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import ApiService from '../../../apiService'
import AdminMessagesModal from '../../../components/Admin/Job/AdminMessagesModal.vue'

export default {
  name: 'AdminJobMonitor',
  components: {
    AdminMessagesModal,
    Pagination,
    Breadcrumb
  },
  data () {
    return {
      status: -1,
      loaded: false,
      searchTerm: '',
      items: [],
      pagination: {
        per_page: 10,
        page: 1,
        total: 0
      },
      highlightRow: [],
      selected: {
        threadHash: '',
        buyerUsername: ''
      }
    }
  },
  async created () {
    await this.getJobItems()
    this.highlightRow = JSON.parse(localStorage.getItem('job_monitor')) || []
  },
  methods: {
    async getJobItems (page) {
      this.loaded = false
      let paramsData = {}
      if (this.searchTerm !== '') {
        paramsData.term = this.searchTerm
      }

      await ApiService.getAdminJobMonitor(paramsData, page).then((resp) => {
        this.items = resp.data.data.results
        this.pagination = resp.data.data.pagination
      }).finally(() => {
        this.loaded = true
      })
    },
    showMessages (thread, buyerUsername) {
      this.selected.threadHash = thread
      this.selected.buyerUsername = buyerUsername
      this.$bvModal.show('messages-modal')
    },
    checked (thread, date) {
      const hash = thread + '-' + date
      const index = this.highlightRow.indexOf(hash)

      if (index > -1) {
        this.highlightRow.splice(index, 1)
      } else {
        this.highlightRow.push(hash)
      }
      localStorage.setItem('job_monitor', JSON.stringify(this.highlightRow))
    },
    displayDatetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  },
  computed: {
    paginationInfo () {
      const currentTotalPosition = this.pagination.per_page * (this.pagination.page - 1)
      return this.$t('showing', { from: currentTotalPosition + 1, to: this.items.length + currentTotalPosition, total: this.pagination.total })
    }
  }
}
</script>
