<template>
  <b-modal id="job-notes-modal"
           :title="`Job Notes for '${job.title}'`"
           title-class="font-weight-bold w-100"
           size="lg"
           :ok-only="true"
           @show="getJobNotes"
           scrollable
  >
    <template v-if="loaded">
      <template v-if="notes.length < 1">
        <div class="m-5 text-center">
          <i class="fa-regular fa-comment-dots fa-5x mb-2"></i>
          <p>No notes have been made</p>
        </div>
      </template>
      <div class="bg-dark text-white p-3 m-3 rounded" v-for="(note, index) in notes" :key="index" v-else>
      <div class="d-flex justify-content-between border-bottom mb-2">
        <div class="uppercase">
          {{ note.user.username }}
        </div>
        <div>
          {{ displayDatetime(note.date_created) }}
        </div>
      </div>
      <div class="text-left">
        {{ note.message }}
      </div>
      <div class="text-right small">
        <i class="fa-solid" :class="note.display ? 'fa-lock-open' : 'fa-lock'"/>
      </div>
    </div>
    </template>
    <div class="mx-auto my-5 text-center" v-else>
      <b-spinner large label="Large Spinner"/>
      <div>Loading data</div>
    </div>
  </b-modal>
</template>

<script>
import ApiService from '../../../apiService'

export default {
  name: 'AdminJobNotesModal',
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      notes: []
    }
  },
  methods: {
    async getJobNotes () {
      this.loaded = false
      await ApiService.getJobNotes(this.job.hash).then((resp) => {
        this.notes = resp.data.data.reverse()
        this.loaded = true
      })
    },
    displayDatetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }

  }
}
</script>
